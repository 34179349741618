<template>
  <div>
    <template v-if="!loading && files.Peque.length > 0">
      <v-col class="my-0 py-0" cols="12" style="max-height: 100vh">
        <v-carousel class="hidden-sm-and-up"
          v-if="files.Peque.length > 0"
          cycle 
          hide-delimiters
          show-arrows-on-hover
          height="auto">
          <v-carousel-item
            v-for="(item,i) in files.Peque"
            :key="i"
          >
            <v-img
              :src="item"
            ></v-img>
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </template>
    <template v-if="!loading && files.Grande.length > 0">
      <v-col class="my-0 py-0" cols="12"  style="max-height: 100vh">
        <v-carousel class="hidden-xs-only"
          v-if="files.Grande.length > 0"
          cycle 
          hide-delimiters
          show-arrows-on-hover
          height="auto">
          <v-carousel-item
            v-for="(item,i) in files.Grande"
            :key="i"
          >
            <v-img
              :src="item"
            ></v-img>
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </template>
    <v-container v-if="!loading && (files.Peque.length == 0 || files.Grande.length == 0)"
    :class="(files.Peque.length == 0 ? '' : 'hidden-xs-only') + ' ' + (files.Grande.length == 0 ? '' : 'hidden-sm-and-up')">
    <!-- <v-row class= "text-center">
      <v-col sm-cols="6" lg-cols="9" xl-cols="12">
        <h3 style="backgroundColor:red">{{$store.state.usuario}}</h3>
      </v-col>
    </v-row> -->
    <v-row class="text-center">
      <v-col cols="12">
        <v-img v-if="this.$store.state.look.logoGrande != null"
          :src="require('../assets/' + this.$store.state.look.logoGrande)"
          class="my-3"
          contain
          height="250"
        />
      </v-col>

      <v-col class="mb-4">
        <!-- <h3 class="display-1 font-weight-bold mb-3"> -->
        <h3 class="headline font-weight-bold mb-3">
          {{this.$store.state.look.textoBienvenida1}} <br> {{this.$store.state.look.textoBienvenida2}}
        </h3>
        <br>
        <template v-if="$store.state.empId != 50">
          <p class="subheading font-weight-regular">
            Nuestro sitio web de colaboración y difusión
            <br>de contenidos de empresa
          </p>
        </template>
      </v-col>

      <template v-if="$store.state.empId != 50">
        <v-col class="mb-5" cols="12">
          <h2 class="title font-weight-bold mb-3">
            Enlaces interesantes
          </h2>
          <v-row justify="center">
            <!-- <ul>
            <template v-for="(link, i) in importantLinks">
                <a
                  :key="i"
                  :href="link.href"
                  class="body-1 mr-1"
                  target="_blank"
                  style="display: block"
                >
                  {{ link.text }}
                </a>
            </template>
            </ul> -->
            <ul>
              <a
                v-for="(link, i) in importantLinks"
                :key="i"
                :href="link.href"
                class="body-1 mr-1"
                target="_blank"
                style="display: block"
              >
                {{ link.text }}
              </a>
            </ul>
          </v-row>
        </v-col>
      </template>
    </v-row>
    </v-container>
    
  </div>
</template>

<script>
  import axios from "axios";
  import { mapState } from "vuex";
  export default {
    name: 'Home',
    computed:{
        ...mapState(['empId', 'urlRaiz'])
    },
    data: () => ({
      importantLinks: [
        {
          text: 'Frutos secos El Rincón',
          href: 'https://www.fselrincon.com/',
        },
        {
          text: 'Tienda on line El Rincón',
          href: 'https://tienda.fselrincon.com/',
        },
        {
          text: 'Tostados de Calidad',
          href: 'https://tostadosdecalidad.com/',
        },
        {
          text: 'El gallo rojo',
          href: 'https://www.elgallorojo.com/',
        },
        {
          text: 'El Rincón de las flores',
          href: 'https://elrincondelasflores.com/',
        },
        {
          text: 'Martín Martín',
          href: 'http://www.martinmartin.es/',
        },
        {
          text: 'Servicio de catering El Rincón',
          href: 'https://www.fselrincon.com/catering/',
        },
      ],
      loading: true,
      files: {
        Grande: [],
        Peque: []
      }
    }),
    mounted() {
      this.getDataFromApi()
        .then(data => {
          data.files.Grande.forEach(val => {
            const path = this.getImagePath(val, 'Grande', data.files.EmpFolder);
            this.files.Grande.push(path);
          });
          data.files.Peque.forEach(val => {
            const path = this.getImagePath(val, 'Peque', data.files.EmpFolder);
            this.files.Peque.push(path);
          });
          this.loading = false;
        });
    },
    methods: {
      getDataFromApi() {
        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'GET_BANNER_LIST',
            EmpId: this.empId,
            EsInterna: this.$store.state.esDepartamento || this.$store.state.esTienda
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/home", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const files = result.data;

            setTimeout(() => {
                resolve({
                  files
                })
            }, 1000)
          })
          .catch(error => {
            if (error != null) {
              this.loading = false;
            }
          });
        })
      },
      getImagePath(fileName, size, empFolder) {
        const internaExternaFolder = this.$store.state.esDepartamento || this.$store.state.esTienda ? 'Interna' : 'Externa'
        //const path = this.urlRaiz + '/RepositorioDocumentos/' + empFolder + '/SliderHome/' + internaExternaFolder + '/' + size + '/' + fileName;
        var path = 'https://flamendancostorage01.file.core.windows.net/intranet/Intranet/' + empFolder + '/SliderHome/' + internaExternaFolder + '/' + size + '/' + fileName + '?sv=2022-11-02&ss=f&srt=o&sp=r&se=2100-05-30T16:30:40Z&st=2024-05-30T08:30:40Z&spr=https&sig=C6H0qHt1bkVeFKXkuaD2QWrLlfmABquhK%2FjYSSQEfOc%3D';
        
        if(this.$store.state.esTiendaCliente && this.$store.state.empId == 8){
          path = 'https://flamendancostorage01.file.core.windows.net/intranet/Intranet/Martin/SliderHomeClientes/Externa/Grande/logopalancas.png?sv=2022-11-02&ss=f&srt=o&sp=r&se=2100-05-30T16:30:40Z&st=2024-05-30T08:30:40Z&spr=https&sig=C6H0qHt1bkVeFKXkuaD2QWrLlfmABquhK%2FjYSSQEfOc%3D';
        }
        return path;
      },
    }
  }
</script>
